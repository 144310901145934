const useFormErrorsAndData = (data: any) => {
  /**
   * Takes a typical response from useActionData or fetcher.data and prepares
   * it so that we can use it in our routes more easily.
   */
  if (!data) {
    return {}
  }

  const { formErrors, formData, ...other } = data

  let formErrorsParsed
  if (formErrors && typeof formErrors === 'string') {
    formErrorsParsed = JSON.parse(formErrors)
  } else {
    formErrorsParsed = formErrors || {}
  }

  return {
    formData,
    formErrors: formErrorsParsed,
    ...other,
  }
}

export default useFormErrorsAndData
